import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const Contact = (): JSX.Element => (
    <section id="contact">
        <div className="inner">
            <section className="spotlights">
                <section>
                    <Link to="/details/9670" className="image">
                        <StaticImage
                            src="../assets/images/stitchedpunk-example-9670.jpg"
                            alt="Example of StitchedPunk #9670"
                        />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Interested?</h3>
                            </header>
                            <p>
                                Want to get a StitchedPunk? Take a look at your profile page and submit an order right
                                away!
                            </p>
                            <ul className="actions">
                                <li>
                                    <Link to="/profile" className="button next">
                                        My Profile
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
            </section>
            <section className="split">
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-envelope"></span>
                        <h3>Email</h3>
                        <a href="mailto:mail@stitchedpunks.com">mail@stitchedpunks.com</a>
                    </div>
                </section>
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-twitter"></span>
                        <h3>Twitter</h3>
                        <span>
                            <a href="https://twitter.com/StitchedPunks">@StitchedPunks</a>
                        </span>
                    </div>
                </section>
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-wrench"></span>
                        <h3>Details</h3>
                        <ul className="actions">
                            <li>
                                <a href="https://github.com/ColdDevil/com.stitchedpunks">GitHub</a>
                            </li>
                            <li>
                                <a href="https://etherscan.io/address/0x9f4263370872b44ef46477dc9bc67ca938e129c6">
                                    Shop Contract
                                </a>
                            </li>
                            <li>
                                <a href="https://etherscan.io/address/0xee2f4a0572b1efbaeca070f8f08d2917eac38dc9">
                                    NFT Contract
                                </a>
                            </li>
                            <li>
                                <a href="https://app.ens.domains/name/stitchedpunks.eth">StitchedPunks.eth</a>
                            </li>
                            <li>
                                <a href="https://opensea.io/collection/stitchedpunks">OpenSea</a>
                            </li>
                        </ul>
                    </div>
                </section>
            </section>
        </div>
    </section>
);

export default Contact;
