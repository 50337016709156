// official contract addresses
export const CRYPTOPUNKS_CONTRACT = '0xb47e3cd837dDF8e4c57F05d70Ab865de6e193BBB';
export const WRAPPEDPUNKS_CONTRACT = '0xb7F7F6C52F2e2fdb1963Eab30438024864c313F6';
export const STITCHEDPUNKSSHOP_CONTRACT = '0x9f4263370872b44EF46477DC9Bc67ca938e129c6';
export const STITCHEDPUNKSNFT_CONTRACT = '0xEE2F4a0572B1EFbaEca070f8F08d2917EAC38dc9';
export const ICON_EMPTY_FILENAME = 'empty-icon.jpg';
export const ICON_EMPTY_COLORED_FILENAME = 'empty-icon-colored.jpg';

// saving and sorting smart contract events
export enum PunkEventType {
    AddPunk = 'AddPunk',
    RemovePunk = 'RemovePunk',
}

export interface PunkEvent {
    eventType: PunkEventType;
    punkId: number;
    blocknumber: number;
}

export class PunkEventSorter {
    private _eventList: PunkEvent[] = [];
    private _punkList: number[] = [];

    addPunk = (punkId: number, blocknumber: number): void => {
        console.log('adding', punkId);
        this._eventList.push({ eventType: PunkEventType.AddPunk, punkId, blocknumber });
        // console.log('eventList', JSON.stringify(this._eventList));
    };

    removePunk = (punkId: number, blocknumber: number): void => {
        console.log('removing', punkId);
        this._eventList.push({ eventType: PunkEventType.RemovePunk, punkId, blocknumber });
        // console.log('eventList', JSON.stringify(this._eventList));
    };

    mergeEvents = (): void => {
        console.log('mergeEvents');

        // order by blocknumber
        console.log('mergeEvents: sort');
        this._eventList.sort((a, b) => a.blocknumber - b.blocknumber);
        console.log('eventList sorted', JSON.stringify(this._eventList));

        // merge events to derive the current list of punks
        this._eventList.forEach((ev) => {
            if (ev.eventType === PunkEventType.AddPunk) {
                // add only if not already existing
                if (this._punkList.indexOf(ev.punkId) === -1) {
                    this._punkList.push(ev.punkId);
                }
            } else if (ev.eventType === PunkEventType.RemovePunk) {
                // check if punk is in list
                const indexOfPunk = this._punkList.indexOf(ev.punkId);
                if (indexOfPunk !== -1) {
                    // remove punk from list
                    this._punkList.splice(indexOfPunk, 1);
                }
            }
        });

        this._punkList.sort((a, b) => a - b);

        console.log('punkList', JSON.stringify(this._punkList));
    };

    getPunkList = (): number[] => {
        return this._punkList;
    };
}

// order status as used in the smart contract
export const orderStatusToString = (status: string): string => {
    if (status === '0') {
        return 'Not ordered yet';
    } else if (status === '10') {
        return 'Order created and paid';
    } else if (status === '20') {
        return 'Crafting StitchedPunk';
    } else if (status === '30') {
        return 'Shipped';
    } else if (status === '40') {
        return 'Received and NFT redeemed';
    }
    return 'Unknown';
};

export const truncAddress = (address: string): string => {
    return address.substr(0, 8) + '...';
};

export const createTxViewerUrl = (txHash: string): string => {
    return 'https://etherscan.io/tx/' + txHash;
};

export const createAddressViewerUrl = (address: string): string => {
    return 'https://etherscan.io/address/' + address;
};

// very rough email check, source: https://stackoverflow.com/a/5166806/2254480
export const validateEmail = (email: string): boolean => {
    const lastAtPos = email.lastIndexOf('@');
    const lastDotPos = email.lastIndexOf('.');
    return (
        lastAtPos < lastDotPos &&
        lastAtPos > 0 &&
        email.indexOf('@@') === -1 &&
        lastDotPos > 2 &&
        email.length - lastDotPos > 2
    );
};

export const isValidPunkIndex = (punkIndex: number): boolean => {
    return !isNaN(punkIndex) && punkIndex >= 0 && punkIndex <= 9999;
};

export const formatPrice = (price: string): string => {
    if (price.endsWith('.0')) {
        return price.substr(0, price.length - 2);
    }

    return price;
};
