/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const Header = ({ onToggleMenu }: { onToggleMenu: () => void }): JSX.Element => (
    <header id="header" className="alt">
        <Link to="/" className="logo-link">
            <StaticImage
                src="../assets/images/stitchedpunks-logo.png"
                alt="StitchedPunks Logo"
                placeholder="blurred"
                layout="fixed"
                width={75}
                height={75}
                className="header-logo"
            />
        </Link>
        <Link to="/" className="logo">
            <strong>Stitched</strong> <span>Punks</span>
        </Link>
        <nav>
            <a className="menu-link" role="button" onClick={onToggleMenu}>
                Menu
            </a>
        </nav>
    </header>
);

export default Header;
