/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useWeb3React } from '@web3-react/core';
import { Link } from 'gatsby';
import React from 'react';
import { truncAddress } from '../web3/BlockchainUtils';
import { injected as injectedConnector } from '../web3/connectors';

interface MenuProps {
    onToggleMenu: () => void;
}

const Menu = (props: MenuProps): JSX.Element => {
    const web3 = useWeb3React();

    return (
        <nav id="menu">
            <div className="inner">
                <ul className="links">
                    <li>
                        <Link onClick={props.onToggleMenu} to="/">
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link onClick={props.onToggleMenu} to="/about">
                            About
                        </Link>
                    </li>
                    <li>
                        <Link onClick={props.onToggleMenu} to="/details">
                            Overview
                        </Link>
                    </li>
                    <li>
                        <Link onClick={props.onToggleMenu} to="/gallery">
                            Gallery
                        </Link>
                    </li>
                    <li>
                        <Link onClick={props.onToggleMenu} to="/profile">
                            My Profile
                        </Link>
                    </li>
                    <li>
                        <Link onClick={props.onToggleMenu} to="/legal">
                            Legal / Impressum
                        </Link>
                    </li>
                </ul>
                <ul className="actions vertical">
                    <li>
                        <a
                            onClick={() => (web3.active ? web3.deactivate() : web3.activate(injectedConnector))}
                            role="button"
                            className="button special fit">
                            {web3.active ? 'Disconnect MetaMask' : 'Connect MetaMask'}
                        </a>
                    </li>
                    <li>
                        <div>
                            Status:{' '}
                            {web3.active ? (
                                <>
                                    Connected to MetaMask <span className="icon fa-check-circle"></span>
                                </>
                            ) : (
                                <>
                                    Not connected to MetaMask <span className="icon fa-times-circle"></span>
                                </>
                            )}
                        </div>
                        <div>
                            Wallet: {web3.account ? truncAddress(web3.account.toLowerCase()) : 'No account found'}
                        </div>
                        {/* <div>Chain ID: {web3.chainId ? web3.chainId : 'Unknown'}</div> */}
                        {/* <div>Web3: {web3.library === undefined ? 'Not loaded' : 'Loaded'}</div> */}
                    </li>
                </ul>
            </div>
            <a className="close" role="button" onClick={props.onToggleMenu}>
                Close
            </a>
        </nav>
    );
};

export default Menu;
