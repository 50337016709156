import { Link } from 'gatsby';
import React from 'react';

const Footer = (): JSX.Element => (
    <footer id="footer">
        <div className="inner">
            <ul className="copyright">
                <li>&copy; 2021 StitchedPunks.com</li>
                <li>
                    Created with <a href="https://www.gatsbyjs.com/">GatsbyJS</a>,{' '}
                    <a href="https://github.com/NoahZinsmeister/web3-react">web3-react</a> and{' '}
                    <a href="https://github.com/ethers-io/ethers.js/">ether.js</a>
                </li>
                <li>
                    <Link to="/legal">Legal / Imprint / Contact</Link>
                </li>
            </ul>
        </div>
    </footer>
);

export default Footer;
