import React, { ReactNode, useState } from 'react';
import '../assets/scss/main.scss';
import Web3ConnectionManager from '../web3/Web3ConnectionManager';
import Contact from './Contact';
import Footer from './Footer';
import Header from './Header';
import Menu from './Menu';

const Layout = ({ children }: { children: ReactNode }): JSX.Element => {
    const [isMenuVisible, setMenuVisible] = useState(false);

    const handleToggleMenu = () => {
        setMenuVisible(!isMenuVisible);
    };

    return (
        <Web3ConnectionManager>
            <div className={`body ${isMenuVisible ? 'is-menu-visible' : ''}`}>
                <div id="wrapper">
                    <Header onToggleMenu={handleToggleMenu} />
                    {children}
                    <Contact />
                    <Footer />
                </div>
                <Menu onToggleMenu={handleToggleMenu} />
            </div>
        </Web3ConnectionManager>
    );
};

export default Layout;
